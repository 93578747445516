import { FC } from "react";
import cx from "classnames";
import { SplitContentComponentFragment } from "services/datocms/generated";
import { SplitContentImage } from "./SplitContentImage";
import { SplitContentText } from "./SplitContentText";

// TailwindCSS requires class names to be available at build time.
const orderClasses = {
  1: "max-lg:order-1",
  2: "max-lg:order-2",
} as Record<number, string>;

export const SplitContentBlock: FC<{
  content: SplitContentComponentFragment;
}> = ({ content }) => {
  const { showSecondBlockFirstOnMobile = false } = content;
  const totalItems = content.content.length;
  return (
    <div
      style={{
        backgroundColor: content.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className="container mx-auto gap-6 grid grid-cols-1 lg:grid-cols-2 p-6">
        {content.content.map((item, idx) => {
          if (item.__typename === "SplitContentImageRecord") {
            return (
              <SplitContentImage
                key={item.id}
                content={item}
                className={cx({
                  [orderClasses[totalItems - idx]]:
                    showSecondBlockFirstOnMobile,
                })}
              />
            );
          } else {
            return (
              <SplitContentText
                key={item.id}
                content={item}
                className={cx({
                  [orderClasses[totalItems - idx]]:
                    showSecondBlockFirstOnMobile,
                })}
              />
            );
          }
        })}
      </div>
    </div>
  );
};
