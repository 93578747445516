import cx from "classnames";
import { Button } from "components/Button";
import { CustomDatoImage } from "components/CustomDatoImage/CustomDatoImage";
import { Alert } from "components/common/Alert/Alert";
import { useTranslationContext } from "context/translation";
import { useNewsletterForm } from "hooks/newsletter/useNewsletterForm";
import { FC } from "react";
import { StructuredText } from "react-datocms/structured-text";
import { Field, Form } from "react-final-form";
import { NewsletterFragment } from "services/datocms/generated";
import st from "./newsletter-signup.module.scss";

type NewsletterSignupProps = {
  newsletterData?: NewsletterFragment | null;
};

const NewsletterSignup: FC<NewsletterSignupProps> = ({ newsletterData }) => {
  const { unknownError } = useTranslationContext();
  const { onSubmit, data, error } = useNewsletterForm(newsletterData);

  if (!newsletterData) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: newsletterData.backgroundColor?.hex ?? "transparent",
      }}
    >
      <div className={cx(st.subcontainer, "container")}>
        <div className={st.textContainer}>
          {data ? (
            <StructuredText data={newsletterData.completedText} />
          ) : (
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <span className={st.title}>{newsletterData.headerText}</span>
                  <div className={st.inputContainer}>
                    <Field
                      component="input"
                      name="email"
                      type="email"
                      placeholder={newsletterData.emailPlaceholder ?? ""}
                      required
                    />
                    <Button>{newsletterData.subscribeButtonText}</Button>
                  </div>
                  {error && <Alert type="error">{unknownError}</Alert>}
                  <div className={st.checkboxContainer}>
                    {newsletterData.showFirstTermsCheckbox ? (
                      <>
                        <Field
                          component="input"
                          type="checkbox"
                          name="accept_marketing"
                          id="acceptMarketingBlogBody"
                        />
                        <label htmlFor="acceptMarketingBlogBody">
                          <StructuredText data={newsletterData.firstTerms} />
                        </label>
                      </>
                    ) : (
                      <StructuredText data={newsletterData.firstTerms} />
                    )}
                  </div>
                  <div className={st.checkboxContainer}>
                    {newsletterData.showSecondTermsCheckbox ? (
                      <>
                        <Field
                          component="input"
                          type="checkbox"
                          name="opt_in"
                          id="optInBlogBody"
                        />
                        <label htmlFor="optInBlogBody">
                          <StructuredText data={newsletterData.secondTerms} />
                        </label>
                      </>
                    ) : (
                      <StructuredText data={newsletterData.secondTerms} />
                    )}
                  </div>
                </form>
              )}
            </Form>
          )}
        </div>
        {newsletterData.backgroundImage?.responsiveImage && (
          <div className={st.hero}>
            <CustomDatoImage
              layout="responsive"
              objectFit="cover"
              data={newsletterData.backgroundImage.responsiveImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterSignup;
