import cx from "classnames";
import Hero from "components/Hero/Hero";
import { ColumnContentBlock } from "components/ProductDetails/Content/ColumnContentBlock/ColumnContentBlock";
import { RichTextBlock } from "components/ProductDetails/Content/RichTextBlock/RichTextBlock";
import { SplitContentBlock } from "components/ProductDetails/Content/SplitContentBlock/SplitContentBlock";
import { FeaturedArticles } from "components/blog/FeaturedArticles";
import { ProductsSlider } from "components/homepage/BestSellerSection/ProductsSlider";
import { BigTilesSection } from "components/homepage/BigTilesSection/BigTilesSection";
import { HeroImageSection } from "components/homepage/HeroImageSection";
import { SmallTilesSection } from "components/homepage/SmallTilesSection/SmallTilesSection";
import { SplitHeroWithTextAndImage } from "components/homepage/SplitHeroWithTextAndImage/SplitHeroWithTextAndImage";
import { FC, useState } from "react";
import { TabsComponentFragment } from "services/datocms/generated";
import { ExternalVideo } from "../Dato/ExternalVideo";
import { Faq } from "../Faq/Faq";
import { QuickLinksBlock } from "../QuickLinksBlock/QuickLinksBlock";
import st from "./index.module.scss";

type TabsProps = {
  component: TabsComponentFragment | undefined | null;
};

export const Tabs: FC<TabsProps> = ({ component }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  if (!component || !component.tabs) {
    return null;
  }

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
  };

  let bannerComponentsCount = 0;

  const renderTabComponent = (block: any) => {
    if (!block) return null;

    switch (block.__typename) {
      case "HomepageHeroComponentRecord":
        if (!block.showOnMobile) {
          ++bannerComponentsCount;
        }
        return (
          <Hero key={block.id} data={block} position={bannerComponentsCount} />
        );
      case "ColumnContentComponentRecord":
        return <ColumnContentBlock key={block.id} content={block} />;
      case "HeroImageComponentRecord":
        ++bannerComponentsCount;
        return (
          <HeroImageSection
            key={block.id}
            content={block}
            position={bannerComponentsCount}
          />
        );
      case "RichTextComponentRecord":
        return <RichTextBlock key={block.id} content={block} />;
      case "ProductsSliderComponentRecord":
        return <ProductsSlider key={block.id} data={block} />;
      case "LinksAsCardsComponentRecord":
        return <QuickLinksBlock key={block.id} data={block} />;
      case "SplitContentComponentRecord":
        return <SplitContentBlock key={block.id} content={block} />;
      case "SplitHeroComponentRecord":
        return <SplitHeroWithTextAndImage key={block.id} data={block} />;
      case "BigTilesComponentRecord":
        return <BigTilesSection key={block.id} content={block} />;
      case "SmallTilesComponentRecord":
        return <SmallTilesSection key={block.id} content={block} />;
      case "FeaturedBlogPostComponentRecord":
        return <FeaturedArticles key={block.id} content={block} />;
      case "ExternalVideoRecord":
        return <ExternalVideo key={block.id} content={block} />;
      case "FaqComponentRecord":
        return <Faq key={block.id} data={block} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={st.wrapper}>
        <div className={st.navContainer}>
          {component.tabs.map((section, index) => (
            <span
              key={index}
              className={cx(st.tab, selectedTab === index && st.activeLink)}
              onClick={() => handleTabClick(index)}
            >
              {section.tabTitle}
            </span>
          ))}
        </div>
      </div>
      <div className="flex-1">
        {component.tabs[selectedTab]?.tabComponents &&
          renderTabComponent(component.tabs[selectedTab].tabComponents)}
      </div>
    </>
  );
};
