import cx from "classnames";
import { Button } from "components/Button";
import { FC } from "react";
import { Image } from "react-datocms";
import { SplitContentImageFragment } from "services/datocms/generated";

export const SplitContentImage: FC<{
  content: SplitContentImageFragment;
  className?: string;
}> = ({ content, className }) => {
  const { button, image } = content;

  return (
    <div className={cx("flex relative justify-center items-center", className)}>
      {image?.responsiveImage && <Image data={image?.responsiveImage} />}
      {button && (
        <div
          className={cx(
            "flex absolute p-5 w-full",
            button.horizontalPosition === "center" && "justify-center",
            button.horizontalPosition === "right" && "justify-end",
            button.verticalPosition === "center" &&
              "absolute top-1/2 -translate-y-1/2",
            button.verticalPosition === "bottom" && "absolute bottom-0",
          )}
        >
          <Button>{button.buttonText}</Button>
        </div>
      )}
    </div>
  );
};
