import Hero from "components/Hero/Hero";
import { ColumnContentBlock } from "components/ProductDetails/Content/ColumnContentBlock/ColumnContentBlock";
import { RichTextBlock } from "components/ProductDetails/Content/RichTextBlock/RichTextBlock";
import { SplitContentBlock } from "components/ProductDetails/Content/SplitContentBlock/SplitContentBlock";
import { CollectionListing } from "components/ProductListing/CollectionListing";
import { FeaturedArticles } from "components/blog/FeaturedArticles";
import NewsletterSignup from "components/blog/blog-post/NewsletterSignup";
import { ProductsSlider } from "components/homepage/BestSellerSection/ProductsSlider";
import { BigTilesSection } from "components/homepage/BigTilesSection/BigTilesSection";
import { HeroImageSection } from "components/homepage/HeroImageSection";
import { SmallTilesSection } from "components/homepage/SmallTilesSection/SmallTilesSection";
import { SplitHeroWithTextAndImage } from "components/homepage/SplitHeroWithTextAndImage/SplitHeroWithTextAndImage";
import { FC } from "react";
import { ComponentsWrapperFragment } from "services/datocms/generated";
import { ExternalVideo } from "../Dato/ExternalVideo";
import { Faq } from "../Faq/Faq";
import { QuickLinksBlock } from "../QuickLinksBlock/QuickLinksBlock";
import { Tabs } from "../Tabs/Tabs";

type DatoComponentsWrapperProps = {
  components: ComponentsWrapperFragment | undefined | null;
  analyticsName?: string;
};

export const DatoComponentsWrapper: FC<DatoComponentsWrapperProps> = ({
  components,
  analyticsName,
}) => {
  if (!components) {
    return null;
  }

  let bannerComponentsCount = 0;

  return (
    <div className="flex-1 w-full">
      {components.components.map((block) => {
        switch (block.__typename) {
          case "HomepageHeroComponentRecord":
            ++bannerComponentsCount;
            return (
              <Hero
                key={block.id}
                data={block}
                position={bannerComponentsCount}
              />
            );
          case "ColumnContentComponentRecord":
            return <ColumnContentBlock key={block.id} content={block} />;
          case "HeroImageComponentRecord":
            ++bannerComponentsCount;
            return (
              <HeroImageSection
                key={block.id}
                content={block}
                position={bannerComponentsCount}
              />
            );
          case "RichTextComponentRecord":
            return <RichTextBlock key={block.id} content={block} />;
          case "ProductsSliderComponentRecord":
            return <ProductsSlider key={block.id} data={block} />;
          case "ProductListComponentRecord":
            return (
              <CollectionListing
                key={block.id}
                data={block}
                analyticsName={analyticsName}
              />
            );
          case "LinksAsCardsComponentRecord":
            return <QuickLinksBlock key={block.id} data={block} />;
          case "SplitContentComponentRecord":
            return <SplitContentBlock key={block.id} content={block} />;
          case "SplitHeroComponentRecord":
            return <SplitHeroWithTextAndImage key={block.id} data={block} />;
          case "BigTilesComponentRecord":
            return <BigTilesSection key={block.id} content={block} />;
          case "SmallTilesComponentRecord":
            return <SmallTilesSection key={block.id} content={block} />;
          case "FeaturedBlogPostComponentRecord":
            return <FeaturedArticles key={block.id} content={block} />;
          case "ExternalVideoRecord":
            return <ExternalVideo key={block.id} content={block} />;
          case "TabsComponentRecord":
            return <Tabs key={block.id} component={block} />;
          case "FaqComponentRecord":
            return <Faq key={block.id} data={block} />;
          case "NewsletterRecord":
            return <NewsletterSignup key={block.id} newsletterData={block} />;
          default:
            return null;
        }
      })}
    </div>
  );
};
