import { Button } from "components/Button";
import { HomepageHeroComponentFragment } from "services/datocms/generated";
import { useScreenSize } from "hooks/useScreenSize";
import classNames from "classnames";
import Head from "next/head";
import Link from "next/link";
import React from "react";
import { useRouter } from "next/router";
import st from "./Hero.module.scss";

const getFlexPositionVertical = (position: string): string => {
  switch (position) {
    case "top":
      return "flex-start";
    case "bottom":
      return "flex-end";
    case "center":
      return "center";
    default:
      return "center";
  }
};

const getFlexPositionHorizontal = (position: string): string => {
  switch (position) {
    case "right":
      return "flex-end";
    case "left":
      return "flex-start";
    case "center":
      return "center";
    default:
      return "flex-start";
  }
};

const getHorizontalStyles = (
  position: string,
): { paddingLeft?: string; paddingRight?: string } => {
  switch (position) {
    case "right":
      return {};
    case "left":
      return {};
    case "center":
      return {};
    default:
      return {};
  }
};

const getTextAlign = (position: string): "right" | "left" | "center" => {
  switch (position) {
    case "right":
      return "right";
    case "center":
      return "center";
    case "left":
      return "left";
    default:
      return "left";
  }
};

/**
 * Returns the correct CSS class based on showOnMobile / showOnDesktop flags.
 * returns one of "", "hideDesktop", "hideMobile", or "hide"
 */
export function getVisibilityClass(
  showOnMobile: boolean,
  showOnDesktop: boolean,
): string {
  if (showOnMobile && showOnDesktop) {
    // Case 1: Show everywhere -> no extra class
    return "";
  } else if (showOnMobile && !showOnDesktop) {
    // Case 2: Show on mobile, hide on desktop
    return st.hideDesktop;
  } else if (!showOnMobile && showOnDesktop) {
    // Case 3: Hide on mobile, show on desktop
    return st.hideMobile;
  } else {
    // Case 4: Hide on both -> 'hide' (fully hidden)
    return st.hide;
  }
}

// Helper to make it easier to let mobile override desktop values.
function allowMobileOverride<T>(
  isMobile: boolean,
  desktopValue: T,
  mobileValue: T | null | undefined,
): T {
  // nothing to check if there isn't a defined mobile value.
  if (mobileValue == null || mobileValue === "") {
    return desktopValue;
  }
  return isMobile ? mobileValue : desktopValue;
}

type HeroProps = {
  data: HomepageHeroComponentFragment;
  position?: number;
};

/**
 * A full-width hero component that displays an optional header, description, and a button.
 *
 * ### Notes
 * - Dato Name: Homepage Hero Component
 * - This component is memoized to prevent unnecessary re-renders when used in a list.
 * - The parent component is expected to be a list, so we use useMemo to cache the Hero component.
 */
const Hero = React.memo(({ data, position }: HeroProps) => {
  const router = useRouter();
  const { isMobile } = useScreenSize();
  const { showOnMobile, showOnDesktop } = data;
  const showOnlyOnMobile = showOnMobile && !showOnDesktop;
  const textColor = data.textColor?.hex ?? "#fff";
  const buttonBackgroundColor = data.buttonBackgroundColor?.hex ?? "#002238";
  const buttonTextColor = data.buttonTextColor?.hex ?? "#FFFFFF";
  const backgroundImageSrc =
    allowMobileOverride(
      isMobile,
      data?.backgroundImage?.url,
      data?.mobileBackgroundImage?.url,
    ) + "?auto=format";
  const backgroundImageXCenter = allowMobileOverride(
    isMobile,
    data.imageXCenter,
    data.mobileImageXCenter,
  );
  const backgroundImageYCenter = allowMobileOverride(
    isMobile,
    data.imageYCenter,
    data.mobileImageYCenter,
  );
  const backgroundPosition = `${backgroundImageXCenter}% ${backgroundImageYCenter}%`;
  const horizontalPosition = allowMobileOverride(
    isMobile,
    data.horizontalPosition ?? "",
    data.mobileHorizontalPosition,
  );
  const verticalPosition = allowMobileOverride(
    isMobile,
    data.verticalPosition ?? "",
    data.mobileVerticalPosition,
  );
  const analyticsPromoName = allowMobileOverride(
    isMobile,
    data.analyticsPromoName,
    data.mobileAnalyticsPromoName,
  );
  const backgroundImageClickLink = allowMobileOverride(
    isMobile,
    data.backgroundImageClickLink,
    data.mobileBackgroundImageClickLink,
  );

  const handleBackgroundImageClick = (
    evt: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (!backgroundImageClickLink) {
      return;
    }
    router.push(backgroundImageClickLink);
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={backgroundImageSrc}
          as="image"
          fetchPriority="high"
          media={showOnlyOnMobile ? "(max-width: 767px)" : "(min-width: 768px)"}
        />
      </Head>
      <div
        className={classNames(
          st.container,
          getVisibilityClass(showOnMobile, showOnDesktop),
          { [st.hasClickableBackground]: backgroundImageClickLink != "" },
        )}
        style={
          {
            backgroundImage: `url(${backgroundImageSrc})`,
            backgroundPosition: backgroundPosition,
            alignItems: getFlexPositionVertical(verticalPosition),
          } as React.CSSProperties
        }
        onClick={handleBackgroundImageClick}
        bi-visibility=""
        bi-type={`Home Page Banner ${position}`}
        bi-promo-name={analyticsPromoName}
        bi-promo-position={`p-${position}`}
        bi-promo-creative={backgroundImageSrc}
      >
        <div
          className={classNames(
            "responsiveContainer",
            st.content,
            st.paddingResetOnMobile,
          )}
          style={{
            alignItems: getFlexPositionHorizontal(horizontalPosition),
            ...getHorizontalStyles(horizontalPosition),
          }}
        >
          <h1
            className={st.heading}
            style={{
              textAlign: getTextAlign(horizontalPosition),
              color: textColor,
            }}
          >
            {data?.header}
          </h1>
          <span
            className={st.description}
            style={{
              textAlign: getTextAlign(horizontalPosition),
              color: textColor,
            }}
          >
            {data?.description}
          </span>
          <Link
            href={data?.link || ""}
            onClick={(evt) => evt.stopPropagation()}
          >
            <Button
              className={st.button}
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
              }}
            >
              {data?.linkText}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
});

export default Hero;
